import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import Homepage3 from '../HomePage3'
import Aboutpage from '../AboutPage'
import ServiceSingle from '../ServiceSingle'
import AirFreight from '../AirFreight'
import RoadFreight from '../RoadFreight'
import OceanFreight from '../OceanFreight'
import PricingPage from '../PricingPage'
import TeamPage from '../TeamPage'
import ContactPage from '../ContactPage'
import BlogPageSidebar from '../BlogPageSidebar'
import BlogPageFullwidth from '../BlogPageFullwidth'
import BlogSingleSidebar from '../BlogSingleSidebar'
import BlogDetailsFullwidth from '../BlogDetailsFullwidth'


const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
              <Route exact path='/' component={Homepage} />
              <Route path='/home' component={Homepage} />
              <Route path='/home2' component={Homepage2} />
              <Route path='/home3' component={Homepage3} />
              <Route path='/about' component={Aboutpage} />
              <Route path='/servicesingle' component={ServiceSingle} />
              <Route path='/freight' component={AirFreight} />
              <Route path='/road' component={RoadFreight} />
              <Route path='/ocean' component={OceanFreight} />
              <Route path='/pricing' component={PricingPage} />
              <Route path='/team' component={TeamPage} />
              <Route path='/contact' component={ContactPage} />
              <Route path='/blog' component={BlogPageSidebar} />
              <Route path='/blog-fullwidth' component={BlogPageFullwidth} />
              <Route path='/blog-single' component={BlogSingleSidebar} />
              <Route path='/blog-single-fullwidth' component={BlogDetailsFullwidth} />
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
