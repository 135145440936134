import React from 'react';

import './style.css'

const ServiceArea = ( props ) => {

    return(
        <div className="wpo-service-s2">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        <span>We Provide the Best</span>
                        <h2>Our Service</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-truck"></i>
                                </div>
                                <h2>Land Transport</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, obcaecati.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-ship"></i>
                                </div>
                                <h2>Sea Freight</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, obcaecati.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-plane"></i>
                                </div>
                                <h2>Air Freight</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, obcaecati.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-construction"></i>
                                </div>
                                <h2>Logistic Solutions</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, obcaecati.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="flaticon-truck-2"></i>
                                </div>
                                <h2>Packaging and Store</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, obcaecati.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="flaticon-trolley"></i>
                                </div>
                                <h2>Warehousing</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, obcaecati.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default ServiceArea;
